import { Component, OnInit, EventEmitter, Output, Input, } from '@angular/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  @Input() showModalHelpButton;
  @Output() onShowModalHelp = new EventEmitter<null>();
  a1000not200 = true;
  constructor() { }

  ngOnInit() {
  }

  ShowModalHelpClicked() {
    this.onShowModalHelp.emit();    
  }
}