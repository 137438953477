import { Config } from "./legends-repository.service";

export class Legend {
    public type: string;
    fullname: string;
    name: string;
    urlInfo: string;
    a1000not200: boolean;
    constructor(id: string, name: string, a1000not200: boolean) {
        this.name = id;
        this.fullname = name;
        this.a1000not200 = a1000not200;
        let scale = a1000not200 ? "1000" : "200";
        this.urlInfo = Config.web_api + "GetInfo/" + scale + "/" + this.name + "/";
    }
}
