import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Config, LegendsRepositoryService } from 'app/legends-repository.service';

@Component({
  selector: 'app-interdepartmental',
  templateUrl: './interdepartmental.component.html',
  styleUrls: ['./interdepartmental.component.css'],
  providers: [LegendsRepositoryService]
})
export class InterdepartmentalComponent implements OnInit {
  HostName: string;
  GeoJSON1000: string;
  GeoJSON200: string;

  arcgis_service_url: string;
  wms_arcgis_service_url: string;
  wfs_arcgis_service_url: string;
  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    console.log()
    this.HostName=window.location.origin;
    this.GeoJSON1000=this.HostName+Config.GeoJSON1000.substring(1);
    this.GeoJSON200=this.HostName+Config.GeoJSON200.substring(1);
    //this.HostName="http://localhost:29707";
    this.http.get(Config.web_api + "GetConfig", { responseType: 'json' })
      .subscribe(data => {
        this.arcgis_service_url = data['arcgis_service_url'];
        this.wms_arcgis_service_url = data['wms_arcgis_service_url'];
        this.wfs_arcgis_service_url = data['wfs_arcgis_service_url'];
      });
  }
  nothing():void{}
}
