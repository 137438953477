
import { Feature } from "ol";


export class OlMapButtons {

    static BoxZoom(): HTMLButtonElement {
        let boxzoom = document.createElement('button');
        let image = document.createElement('img');
        image.src = './assets/img/box_zoom.png';
        image.alt = 'box_zoom';
        image.classList.add('ol-image');
        boxzoom.appendChild(image);
        boxzoom.id = 'boxzoom';
        boxzoom.title = 'Box Zoom';
        boxzoom.classList.add('ol-image-button');
        return boxzoom;
    }
    static FullExtent(): HTMLButtonElement {
        let fullextent = document.createElement('button');
        let image = document.createElement('img');
        image.src = './assets/img/full_extent.png';
        image.alt = 'Полный экстент';
        image.classList.add('ol-image');
        fullextent.appendChild(image);
        fullextent.title = 'Полный экстент';
        fullextent.classList.add('ol-image-button');
        return fullextent;
    }
    static BaseMap(): HTMLButtonElement {
        let basemap = document.createElement('button');
        let image = document.createElement('img');
        image.src = './assets/img/base_map.png';
        image.alt = 'Карта';
        image.classList.add('ol-image');
        basemap.appendChild(image);
        basemap.title = 'Геологическая карта России';
        basemap.classList.add('ol-image-button');
        return basemap;
    }
    static About(): HTMLButtonElement {
        let about = document.createElement('button');        
        let image = document.createElement('img');
        image.src = './assets/img/about.png';
        image.alt = 'О сервисе';
        image.classList.add('ol-image');
        about.appendChild(image);
        about.title = 'О сервисе';
        about.classList.add('ol-image-button');
        return about;
    }
}