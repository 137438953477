import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/Rx';

@Injectable()
export class LegendsRepositoryService {

  private urlConfig = "./assets/config.json";
  constructor(private http: HttpClient) {

  }
  getConfigs() {
    return this.http.get(this.urlConfig)
      .toPromise()
      .then(res => {
        Config.ggk_url = res['ggk_url'];
        Config.nomenclature_url = res['nomenclature_url'];
        Config.web_api = res['web_api'];
        Config.GeoJSON1000 = res['GeoJSON1000'];
        Config.GeoJSON200 = res['GeoJSON200'];
      });
  }
  GeoJSON200Body: any;
  GeoJSON1000Body: any;

  async getLegends() {

    await this.http.get(Config.GeoJSON200).toPromise().then(x => {
      this.GeoJSON200Body = x;
    });
    await this.http.get(Config.GeoJSON1000).toPromise().then(x => {
      this.GeoJSON1000Body = x
    });
  }
}
export class Config {

  static ggk_url: string;
  static nomenclature_url: string;
  static GeoJSON200: string;
  static GeoJSON1000: string;
  static web_api: string;
}