import { Component, OnInit, ViewChild } from '@angular/core';
import { NavComponent } from './nav/nav.component';
import { OlMapComponent } from './ol-map/ol-map.component';
import { Legend } from './legend';
import { LegendsRepositoryService } from './legends-repository.service';
import { ModalInfoComponent } from './modal-info/modal-info.component';
import { ModalHelpComponent } from './modal-help/modal-help.component';
import { SimpleModalService } from 'ngx-simple-modal';

declare var require: any
var $ = require('jquery');
require("webpack-jquery-ui/resizable");
require("webpack-jquery-ui/draggable");

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [LegendsRepositoryService]
})
export class AppComponent implements OnInit {
  @ViewChild(OlMapComponent)
  myMap: OlMapComponent;
  SelectedID: string;
  SelectedLegend: Legend;
  SerialLegendList: any;
  a1000not200 = false;
  aznot19: boolean = true;
  constructor(private repo: LegendsRepositoryService, private simpleModalService: SimpleModalService) {
  }
  showModalInfo(e) {

    let ismap = (<HTMLElement>e).className != 'legendListItem active';
    if (!(!this.myMap.IsSelectSmth() && ismap)) {
      this.simpleModalService.addModal(ModalInfoComponent, {
        legend: this.SelectedLegend
      });
    };
  }
  showModalHelp() {
    this.simpleModalService.addModal(ModalHelpComponent);
  }

  async ngOnInit() {
    await this.repo.getLegends();
    this.myMap.InitMap();
    document.getElementById("sortaz").removeAttribute('href');
    document.getElementById('sortaz').style.color = "grey";
    $("#sidebar").tabs();
    this.set1000();
  }

  ListItemClicked(listitem) {
    this.ListItemSelect(listitem);
  }
  onLegendChangeOnMap(id: string) {
    this.ListItemSelect(<HTMLElement>document.getElementById(id));
    this.SelectedID = id;
    this.setLegend();
  }
  onLegendListChanged(listitem) {
    let id = (<HTMLElement>listitem).id;
    this.myMap.SelectLegend(id);
    this.ListItemSelect(listitem);
    this.SelectedID = (id);
    this.setLegend();
  }
  ListItemSelect(listitem) {
    var list = document.getElementsByClassName("legendListItem");
    for (let i = 0; i < list.length; i++) {
      (<HTMLElement>list[i]).classList.remove('active');
    }
    (<HTMLElement>listitem).classList.add('active');
  }
  onScaleChanged(a1000not200) {
    this.a1000not200 = a1000not200;
    if (this.a1000not200) this.set1000();
    else this.set200();
  }
  set1000() {
    this.a1000not200 = true;
    this.SerialLegendList = this.repo.GeoJSON1000Body['features'];
    this.SortAZ();
    this.myMap.ChangeVectorLayerOnMap(this.a1000not200);
  }
  set200() {
    this.a1000not200 = false;
    this.SerialLegendList = this.repo.GeoJSON200Body['features'];
    this.SortAZ();
    this.myMap.ChangeVectorLayerOnMap(this.a1000not200);
  }
  setLegend() {
    for (var i in this.SerialLegendList) {
      if (this.SerialLegendList[i].properties.name == this.SelectedID) {
        this.SelectedLegend = new Legend(
          this.SerialLegendList[i].properties.name,
          this.SerialLegendList[i].properties.fullname,
          this.a1000not200);
      }
    }
  }
  Sort19() {
    document.getElementById("sort19").removeAttribute('href');
    document.getElementById('sort19').style.color = "grey";
    document.getElementById("sortaz").setAttribute("href", "#");
    document.getElementById('sortaz').style.color = "#007060";
    this.SerialLegendList.sort(function (a, b) {
      if (a.properties.name.length == 1 && b.properties.name.length > 1)
        return -1;
      if (a.properties.name.length > 1 && b.properties.name.length == 1)
        return 1;
      if (a.properties.name < b.properties.name)
        return -1;
      if (a.properties.name > b.properties.name)
        return 1;
      return 0;
    });
  }
  SortAZ() {
    document.getElementById("sortaz").removeAttribute('href');
    document.getElementById('sortaz').style.color = "grey";
    document.getElementById("sort19").setAttribute("href", "#");
    document.getElementById('sort19').style.color = "#007060";
    this.SerialLegendList.sort(function (a, b) {
      if (a.properties.fullname < b.properties.fullname)
        return -1;
      if (a.properties.fullname > b.properties.fullname)
        return 1;
      return 0;
    });
  }

}
