import { Component, OnInit, Input, Output, ElementRef, HostListener } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SimpleModalComponent } from "ngx-simple-modal";


import { Legend } from '../legend';
import { DomSanitizer } from '@angular/platform-browser';
var $ = require('jquery');

export interface GFParams {
  scale: string;
  num: string;
  fname: string;
}
export interface ConfirmModel {
  title: string;
  message: string;
}
@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.css'],

})
export class ModalInfoComponent extends SimpleModalComponent<any, Response> implements OnInit {
  myTemplate: any = "";
  legend: Legend;
  ignoreBackdropClick = false;

  constructor(private http: HttpClient, private sanitized: DomSanitizer, private elementRef: ElementRef) {
    super();
  }

  ngOnInit() {    
    let hostOrigin=window.location.origin;
    let hostName=window.location.origin;
    this.http.get(this.legend.urlInfo, { responseType: 'text' })
      .subscribe(data => {
        this.myTemplate = this.sanitized.bypassSecurityTrustHtml(data.toString()
        .split(hostOrigin).join('.')
        .split(hostName).join('.')
        );
      });
    $('.modal-content').resizable({
      minWidth: 300,
      minHeight: 500
    }).on('resizestart', function () {
      this.ignoreBackdropClick = true;
    })
      .on('resizeend', function () {
        setTimeout(function () {
          this.ignoreBackdropClick = false;
        }, 0);
      });
    $('.modal-dialog').draggable({
      handle: ".modal-header"
    });
    $('.modal-body').css({
      'overflow-y': 'scroll'
    });
  }
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    console.log("onGlobalClick", this.ignoreBackdropClick);
    if (!this.elementRef.nativeElement.contains(event.target) || this.elementRef.nativeElement == event.target)
      if (!this.ignoreBackdropClick) {
        this.close();
      }
  }

}