import { Component, OnInit, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { SimpleModalComponent } from "ngx-simple-modal";

declare var require: any
var $ = require('jquery');
require('webpack-jquery-ui');
require('webpack-jquery-ui/css');

export interface ConfirmModel {
  title: string;
  message: string;
}
@Component({
  selector: 'app-modal-help',
  templateUrl: './modal-help.component.html',
  styleUrls: ['../modal-info/modal-info.component.css', '../app.component.css']
})
export class ModalHelpComponent extends SimpleModalComponent<ConfirmModel, null> {

  constructor(private elementRef: ElementRef) {
    super();
  }
  ngOnInit() {
    $("#tabs").tabs();
    $("#HelpModal").draggable({
      handle: ".modal-header"
    });
    $('.modal-content').resizable({
      minWidth: 300,
      minHeight: 500
    });
    $('.modal-body').css({
      height: '80vh',
      'max-height': 'calc(100% - 70px)',
      'overflow-y': 'scroll'
    });
    $('#myTab a').click(function (e) {
      e.preventDefault()
      $(this).tab('show')
    })
  }
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target) || this.elementRef.nativeElement == event.target) {
      this.close();
    }
  }
}

