import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Config, LegendsRepositoryService } from 'app/legends-repository.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {

  myTemplate: any = "";
  scale: string = "";
  num: string = "";
  name: string = "";
  subscription: Subscription;
  constructor(
    private http: HttpClient,
    private sanitized: DomSanitizer,
    private route: ActivatedRoute,
    private repo: LegendsRepositoryService) {
    this.subscription = route.params.subscribe(params => {
      this.scale = params['scale'];
      this.num = params['num'];
    });
  }

  async ngOnInit() {
    console.log(this.scale);
    console.log(this.num);
    let hostOrigin = window.location.origin;
    let hostName = window.location.origin;
    this.http.get(Config.web_api + "GetInfo/" + this.scale + "/" + this.num + "/", { responseType: 'text' })
      .subscribe(data => {
        this.myTemplate = this.sanitized.bypassSecurityTrustHtml(data.toString()
          .split(hostOrigin).join('.')
          .split(hostName).join('.')
        );
      });

    await this.repo.getLegends();
    let list;
    if (this.scale == '200')
      list = this.repo.GeoJSON200Body['features'];
    else
      list = this.repo.GeoJSON1000Body['features'];
    for (var i in list) {
      if (list[i].properties.name == this.num) {
        this.name = list[i].properties.fullname;
      }
    }
  }
}
