import {
  AfterViewInit, Component, ElementRef, ViewChild, EventEmitter,
  Output, Injectable
} from "@angular/core";
import { View, Map, Kinetic } from 'ol';
import OSM from 'ol/source/OSM';
import { ScaleLine, MousePosition, ZoomSlider, Control } from 'ol/control';
import { createStringXY } from 'ol/coordinate';
import VectorLayer from 'ol/layer/Vector';
import TileArcGISRest from "ol/source/TileArcGISRest";
import VectorSource from "ol/source/Vector";
import Select from 'ol/interaction/Select';
import MouseWheelZoom from 'ol/interaction/MouseWheelZoom';
import DragPan from 'ol/interaction/DragPan';
import TileLayer from 'ol/layer/Tile';
import * as olProj from 'ol/proj';
import GeoJSON from 'ol/format/GeoJSON';
import { mouseOnly } from "ol/events/condition";
import { Fill, Stroke, Style, Text } from 'ol/style';
import { OlMapButtons } from "./olmap.helpers";
import { Config, LegendsRepositoryService } from "app/legends-repository.service";

import { defaults } from 'ol/interaction';

@Component({
  selector: 'app-ol-map',
  templateUrl: './ol-map.component.html',
  styleUrls: ['./ol-map.component.css'],
})
@Injectable()
export class OlMapComponent {

  @ViewChild("mapElement") mapElement: ElementRef;
  @Output() LegendChange: EventEmitter<string>;
  public map: Map;
  select: any;
  vector_layer: any;
  geomap_layer: any;
  nomenclature_layer: any;
  view: any;
  geomap: boolean = false;
  styleBlue: any;
  styleRed: any;
  styleSelect: any;
  a1000not200 = true;
  constructor(private repo: LegendsRepositoryService) {
    OlMapComponent.prototype.LegendChange = new EventEmitter<string>();

  }
  InitMap() {
    this.view = new View({
      center: olProj.transform([105, 70], 'EPSG:4326', 'EPSG:3857'),
      zoom: 3.2,
      minZoom: 2.5
    });
    this.map = new Map({
      interactions: defaults({
        dragPan: false
      }).extend([new DragPan({ kinetic: new Kinetic(-0.005, 0.05, 100) })]),
      layers: [new TileLayer({
        source: new TileArcGISRest({
          wrapX: true,
          params: { LAYERS: "World_Imagery", LTYPE: "BaseLayer" },
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/export"
        }), zIndex: 0
      })],
      view: this.view
    });
    this.map.setTarget(this.mapElement.nativeElement.id);
    this.olStylesInit();
    this.VectorLayerInit();
    this.map.addLayer(this.vector_layer);
    this.SelectInit();
    this.SelectSet();
    this.GeomapLayerInit();
    this.InitControls();
    this.map.addLayer(this.geomap_layer);
    this.map.addLayer(this.nomenclature_layer);
    this.Geomap();

  }

  InitControls() {

    this.map.removeControl(this.map.getControls().item(2)); // info
    this.map.removeControl(this.map.getControls().item(1)); // rotate
    this.map.addControl(new MousePosition({
      coordinateFormat: createStringXY(4),
      projection: 'EPSG:4326'
    }));
    this.map.addControl(new ScaleLine());
    this.map.addControl(new ZoomSlider());

    // FULL EXTENT
    let fullextent = OlMapButtons.FullExtent();
    fullextent.onclick = (evt) => {
      evt.stopPropagation();
      this.map.getView().animate({ zoom: 3.2 }, { center: olProj.transform([105, 70], 'EPSG:4326', 'EPSG:3857') });
    };
    // BASE MAP
    let basemap = OlMapButtons.BaseMap();;
    basemap.onclick = (evt) => {
      evt.stopPropagation();
      this.Geomap();
    };
    let buttoncontainerTop = document.createElement('div');
    buttoncontainerTop.appendChild(fullextent);
    buttoncontainerTop.appendChild(basemap);
    buttoncontainerTop.classList.add('ol-buttoncontainer');
    buttoncontainerTop.classList.add('ol-selectable');
    buttoncontainerTop.classList.add('ol-control');
    buttoncontainerTop.id = "top";
    this.map.addControl(new Control({ element: buttoncontainerTop }));

    let buttoncontainerBottom = document.createElement('div');
    buttoncontainerBottom.classList.add('ol-buttoncontainer');
    buttoncontainerBottom.classList.add('ol-selectable');
    buttoncontainerBottom.classList.add('ol-control');
    buttoncontainerBottom.id = "bottom";
    this.map.addControl(new Control({ element: buttoncontainerBottom }));

  }
  // Задать слой легенд
  VectorLayerInit() {
    let style = this.geomap ? this.styleBlue : this.styleRed;
    this.vector_layer = new VectorLayer({
      source: new VectorSource({
        url: this.a1000not200 ? Config.GeoJSON1000 : Config.GeoJSON200,
        format: new GeoJSON()
      }),
      style: function (feature, resolution) {
        style.getText().setText(feature.get('name'));
        return style;
      }
    });
    this.vector_layer.setZIndex(10);
  }
  // Задать select
  SelectInit() {
    let style = this.styleSelect;
    this.select = new Select(
      {
        condition: mouseOnly,
        style: function (feature, resolution) {
          style.getText().setText(feature.get('fullname'));
          return style;
        }
      }
    );
    this.select.on('select', function (e) {
      e.target.getFeatures().forEach(function (f) {
        OlMapComponent.prototype.ChangeVectorLayerInList(f.get('name'));
      })
    });
  }
  // Добавить select на карту
  SelectSet() {
    this.map.removeInteraction(this.select);
    this.map.addInteraction(this.select);
    let zoom = new MouseWheelZoom({ condition: mouseOnly });
    this.map.addInteraction(zoom);
  }
  // Задать гришины слои
  GeomapLayerInit() {
    this.geomap_layer =
      new TileLayer({
        source: new TileArcGISRest({
          wrapX: true,
          params: {},
          url: Config.ggk_url
        })
      });
    this.nomenclature_layer = new TileLayer({
      source: new TileArcGISRest({
        wrapX: true,
        params: {},
        url: Config.nomenclature_url
      })
    });
  }
  // Подсветить легенду на карте
  SelectLegend(id) {
    let selectedFeatures = this.select.getFeatures();
    selectedFeatures.clear();
    let feature = this.vector_layer.get('source').forEachFeature(function (f) {
      if (f.get('name') == id) return f;
    });
    selectedFeatures.push(feature);
  }
  IsSelectSmth() {
    return this.select.getFeatures().getLength() != 0;
  }
  // Приблизить к легенде
  ZoomTo(id) {
    let feature = this.vector_layer.get('source').forEachFeature(function (f) {
      if (f.get('name') == id) return f;
    });
    this.view.fit(feature.getGeometry(), { padding: [10, 10, 10, 10], nearest: false });
  }
  // Приблизить к карте
  FullExtent() {
    var polygon = [2022007, 5300000, 20037508, 18807214];
    this.view.fit(polygon, { padding: [10, 10, 10, 10], nearest: false });
  }
  // Переключить 1000/200
  ChangeVectorLayerOnMap(a1000not200) {
    this.select.getFeatures().clear();
    this.a1000not200 = a1000not200;
    this.map.removeLayer(this.vector_layer);
    this.VectorLayerInit();
    this.map.addLayer(this.vector_layer);
    this.SelectSet();
  }
  // Подсветить легенду в списке
  ChangeVectorLayerInList(id: string) {
    this.LegendChange.emit(id);
  }
  // Гришины слои добавить/удалить
  Geomap(): boolean {
    this.geomap = !this.geomap;
    let style;
    if (this.geomap) {
      this.geomap_layer.setVisible(true);
      style = this.styleBlue;
    }
    else {
      this.geomap_layer.setVisible(false);
      style = this.styleRed;
    }
    this.vector_layer.setStyle(
      function (feature, resolution) {
        style.getText().setText(feature.get('name'));
        return style;
      });

    return this.geomap;
  }
  ZoomIn() {
    var view = this.map.getView();
    var zoom = view.getZoom();
    view.setZoom(zoom + 0.2);
  }
  ZoomOut() {
    var view = this.map.getView();
    var zoom = view.getZoom();
    view.setZoom(zoom - 0.2);
  }
  olStylesInit() {
    this.styleBlue = new Style({
      fill: new Fill({
        color: 'rgba(181, 208, 208, 0.0)'
      }),
      stroke: new Stroke({
        color: '#aad3df',
        width: 3
      }),
      text: new Text({
        font: '1em Calibri,sans-serif',
        fill: new Fill({
          color: '#000'
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 3
        })
      })
    });
    this.styleRed = new Style({
      fill: new Fill({
        color: 'rgba(224, 128, 128, 0.0)'
      }),
      stroke: new Stroke({
        color: '#CD5C5C',
        width: 2
      }),
      text: new Text({
        font: '1em Calibri,sans-serif',
        fill: new Fill({
          color: '#000'
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 3
        })
      })
    });
    this.styleSelect = new Style({
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.5)'
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 3
      }),
      text: new Text({
        font: '1em Calibri,sans-serif',
        fill: new Fill({
          color: '#000'
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 3
        })
      })
    });
  }
}
