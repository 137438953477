import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rooter',  
  template: `<router-outlet></router-outlet>`
})
export class RooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
