import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { defaultSimpleModalOptions, SimpleModalModule } from 'ngx-simple-modal';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { OlMapComponent } from './ol-map/ol-map.component';
import { ModalInfoComponent } from './modal-info/modal-info.component';
import { ModalHelpComponent } from './modal-help/modal-help.component';
import { HttpClientModule } from '@angular/common/http';
import { DotreplacePipe } from './dotreplace.pipe';
import { LegendsRepositoryService } from './legends-repository.service';
import { RooterComponent } from './rooter.component';
import { RouterModule, Routes } from '@angular/router';
import { InterdepartmentalComponent } from './interdepartmental/interdepartmental.component';
import { InfoComponent } from './info/info.component';

const appRoutes: Routes = [
  { path: 'info/:scale/:num', component: InfoComponent },
  { path: '', component: AppComponent }  
];

export function app_Init(appService: LegendsRepositoryService) {
  return () => appService.getConfigs().then(res => {
    return;
  });
}

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    OlMapComponent,
    ModalInfoComponent,
    ModalHelpComponent,
    DotreplacePipe,
    RooterComponent,
    InterdepartmentalComponent,
    InfoComponent
  ],
  imports: [
    RouterModule.forRoot(appRoutes),
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SimpleModalModule.forRoot({container: 'modal-container'}, {...defaultSimpleModalOptions})
  ],
  entryComponents: [
    ModalInfoComponent,
    ModalHelpComponent
  ],
  providers: [
    RouterModule,
    LegendsRepositoryService,
    { provide: APP_INITIALIZER, useFactory: app_Init, deps: [LegendsRepositoryService], multi: true },
  ],
  bootstrap: [RooterComponent]
})
export class AppModule { }
