import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dotreplace'
})
export class DotreplacePipe implements PipeTransform {

  transform(filename: string): string {
    return filename.replace(".","/");
  }
  
}
